import React from "react"

const LogoInner = () => {
  return (
    <svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 99.99 60.93">
      <path d="M90.09,4.25c1.79-1.78,4.51-2.67,8.15-2.67h1.75V0H62.74v1.58h1.24c3.7,0,6.43,0.88,8.19,2.65
        c1.76,1.76,2.65,4.49,2.65,8.19v16H43.18v-16c0-7.23,3.64-10.84,10.92-10.84h1.75V0H14.76v1.58h4.99c7.28,0,10.92,3.61,10.92,10.84
        v35.03c0,2.5-0.5,4.65-1.49,6.42c-1,1.78-2.43,3.14-4.31,4.07c-1.88,0.94-4.14,1.41-6.78,1.41c-3.24,0-5.86-0.61-7.85-1.84
        c-1.59-0.97-2.39-1.82-2.39-2.56c0-0.45,0.58-0.88,1.75-1.28c3.81-1.34,5.72-3.73,5.72-7.17c0-2.16-0.68-3.94-2.05-5.33
        c-1.37-1.39-3.14-2.09-5.33-2.09s-4.06,0.79-5.61,2.37C0.77,43.03,0,44.92,0,47.11c0,1.34,0.34,2.72,1.02,4.16
        c0.68,1.44,1.59,2.77,2.73,3.99c1.82,1.82,4.11,3.22,6.87,4.2c2.76,0.98,5.86,1.47,9.3,1.47c3.67,0,7.1-0.55,10.28-1.66
        c3.19-1.11,5.87-2.72,8.06-4.82c3.27-3.19,4.91-7.55,4.91-13.1V30.17h31.63v16.94c0,3.67-0.89,6.39-2.67,8.17
        c-1.78,1.78-4.5,2.67-8.17,2.67h-1.24v1.66h37.25v-1.66h-1.75c-3.58,0-6.29-0.9-8.11-2.69c-1.82-1.79-2.73-4.51-2.73-8.15V12.42
        C87.4,8.75,88.3,6.02,90.09,4.25z"/>
    </svg>

  )
}

export default LogoInner