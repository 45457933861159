import React from "react"

const LogoOuter = () => {
    return (
        <svg className="logoOuter" width="100%" height="100%" viewBox="0 0 329.21 336.04">
            <g>
                <path d="M3.64,120.48l3.87-12.09l0.46,0.15l-0.16,0.52c-0.69,2.14,0.03,3.55,2.16,4.23l8.51,2.72c1.63,0.52,2.76,1.41,3.39,2.68
		c0.41,0.84,0.63,1.79,0.66,2.83c0.03,1.04-0.13,2.1-0.48,3.18c-0.32,1.01-0.76,1.88-1.31,2.6c-0.55,0.72-1.18,1.26-1.88,1.63
		c-0.47,0.22-0.94,0.36-1.43,0.43c-0.49,0.07-0.93,0.04-1.32-0.09c-0.64-0.21-1.13-0.61-1.44-1.22c-0.32-0.6-0.38-1.23-0.17-1.87
		c0.21-0.64,0.58-1.1,1.12-1.37c0.54-0.27,1.13-0.3,1.76-0.1c1.01,0.32,1.54,1.11,1.57,2.36c0.01,0.38,0.08,0.59,0.21,0.64
		c0.22,0.07,0.54-0.08,0.98-0.46c0.55-0.47,0.97-1.18,1.28-2.14c0.25-0.78,0.32-1.49,0.22-2.13c-0.1-0.64-0.36-1.19-0.79-1.65
		c-0.43-0.46-1.01-0.81-1.75-1.04l-10.31-3.3c-2.13-0.68-3.53,0.05-4.22,2.19l-0.47,1.47L3.64,120.48z"/>
                <path d="M8.7,105.14l8.43-17.68l6.44,3.07l-0.17,0.36c-1.28-0.21-2.26-0.32-2.95-0.33c-0.68-0.01-1.37,0.07-2.07,0.25
		c-0.83,0.22-1.52,0.56-2.05,1.02c-0.87,0.78-1.64,1.87-2.3,3.27l-1.56,3.27l7.9,3.77l0.42-0.88c0.62-1.31,0.56-2.62-0.2-3.92
		c-0.39-0.67-0.94-1.3-1.65-1.87c-0.3-0.26-0.76-0.59-1.39-0.98l0.2-0.42l10.9,5.2l-0.2,0.42c-0.63-0.18-1.24-0.34-1.82-0.45
		s-1.07-0.17-1.48-0.16c-0.4,0.01-0.83,0.06-1.27,0.16c-1.22,0.29-2.15,1.1-2.77,2.42l-0.33,0.69l7.44,3.55l1.3-2.74
		c0.39-0.81,0.68-1.56,0.87-2.27s0.29-1.37,0.3-2c0-0.63-0.08-1.27-0.25-1.9c-0.29-1.09-0.82-2.09-1.6-2.98
		c-0.45-0.51-1.08-1.14-1.92-1.86l0.21-0.44l6.84,3.26l-8.63,18.1l-0.45-0.19l0.22-0.45c0.96-2.02,0.43-3.51-1.61-4.48l-9.67-4.61
		c-1.03-0.49-1.91-0.61-2.64-0.35c-0.73,0.26-1.34,0.9-1.83,1.93l-0.22,0.45L8.7,105.14z"/>
                <path d="M24.15,77.13l6.64,4.44l0.65-0.96c0.68-1.02,0.88-2.03,0.59-3.04s-1.08-2.14-2.4-3.38l0.26-0.38l8.78,5.87l-0.27,0.41
		c-0.84-0.39-1.54-0.65-2.09-0.78s-1.11-0.17-1.66-0.11c-1.18,0.11-2.16,0.74-2.94,1.9l-0.51,0.76l4.65,3.11
		c0.94,0.63,1.79,0.87,2.56,0.72c0.76-0.15,1.46-0.69,2.08-1.63l0.65-0.98l0.43,0.29l-6.79,10.15l-0.43-0.29l0.26-0.38
		c0.63-0.94,0.87-1.79,0.72-2.56c-0.15-0.76-0.7-1.46-1.64-2.09l-8.91-5.96c-0.95-0.64-1.8-0.88-2.56-0.73
		c-0.76,0.15-1.45,0.7-2.09,1.65l-0.26,0.38l-0.41-0.27l11.2-16.75l6.62,4.43l-0.24,0.36c-0.64-0.27-1.25-0.5-1.81-0.69
		s-1.01-0.3-1.35-0.35c-1.06-0.18-2.02-0.18-2.89-0.01c-0.87,0.17-1.67,0.53-2.41,1.07c-0.73,0.54-1.4,1.26-2.01,2.17L24.15,77.13z"
                />
                <path d="M38.69,57.88l5.94,5.35l0.78-0.86c0.82-0.91,1.16-1.88,1.02-2.93c-0.14-1.04-0.77-2.27-1.89-3.69l0.31-0.34l7.85,7.07
		l-0.33,0.36c-0.77-0.51-1.43-0.87-1.96-1.07s-1.07-0.32-1.62-0.34c-1.19-0.06-2.25,0.43-3.18,1.46l-0.61,0.68l4.15,3.74
		c0.84,0.76,1.65,1.12,2.43,1.08c0.78-0.04,1.54-0.48,2.29-1.31l0.79-0.87l0.38,0.34l-8.17,9.07l-0.38-0.34l0.31-0.34
		c0.76-0.84,1.12-1.65,1.08-2.43c-0.04-0.78-0.48-1.54-1.32-2.3l-7.96-7.17c-0.85-0.76-1.66-1.13-2.43-1.09s-1.54,0.48-2.3,1.33
		l-0.31,0.34l-0.36-0.33l13.48-14.97l5.92,5.33l-0.29,0.32c-0.6-0.36-1.16-0.67-1.69-0.94c-0.53-0.27-0.96-0.44-1.29-0.54
		c-1.02-0.33-1.97-0.47-2.86-0.42c-0.89,0.05-1.73,0.29-2.53,0.72c-0.8,0.43-1.57,1.05-2.3,1.86L38.69,57.88z"/>
                <path d="M56.44,39.31l8.68-6.37l0.29,0.39l-1.06,0.78c-0.48,0.35-0.79,0.73-0.92,1.13c-0.13,0.4-0.06,0.78,0.2,1.14
		c0.25,0.34,0.65,0.67,1.2,0.99l10.57,5.92l-2.22-10.16c-0.16-0.79-0.4-1.39-0.71-1.82c-0.45-0.61-0.99-0.96-1.64-1.04
		c-0.64-0.08-1.32,0.1-2.02,0.56l-0.29-0.39l5.98-4.39l0.29,0.39c-0.52,0.48-0.91,1.02-1.16,1.61s-0.38,1.27-0.38,2.04
		c0,0.77,0.11,1.71,0.35,2.81l3.22,14.73l-0.36,0.29l-14.95-8.32c-1.77-0.98-3.19-1.08-4.27-0.29l-0.52,0.38L56.44,39.31z"/>
                <path d="M82.09,21.51l0.43-0.24l14.35,10.41c0.45,0.32,0.89,0.55,1.3,0.69s0.82,0.18,1.21,0.13c0.39-0.05,0.81-0.2,1.24-0.45
		l0.43-0.24l0.25,0.45l-9.32,5.24l-0.25-0.45l1.22-0.68c0.61-0.34,1-0.67,1.19-0.99s0.18-0.66-0.01-1
		c-0.12-0.21-0.32-0.44-0.61-0.69c-0.28-0.24-0.84-0.66-1.67-1.26c-0.05-0.03-0.13-0.09-0.23-0.17c-0.11-0.08-0.21-0.16-0.32-0.23
		L90.7,31.6l-6.8,3.82l0.15,1.49c0.06,0.5,0.21,0.96,0.44,1.37c0.2,0.35,0.49,0.65,0.88,0.88s0.79,0.35,1.2,0.35
		c0.28,0.01,0.51-0.02,0.7-0.07c0.19-0.05,0.44-0.15,0.76-0.31l0.25,0.45l-6.54,3.68l-0.25-0.45c0.44-0.32,0.77-0.62,0.99-0.89
		c0.21-0.27,0.42-0.64,0.61-1.1c0.46-1.08,0.59-2.71,0.38-4.89L82.09,21.51z M82.98,25.96l0.88,8.93l6.38-3.58L82.98,25.96z"/>
                <path d="M95.91,14.46l6.95-2.53l16.56,8.09l-2.71-7.46c-0.33-0.91-0.77-1.65-1.33-2.22c-0.56-0.57-1.19-0.92-1.91-1.07
		c-0.39-0.08-0.75-0.11-1.1-0.08c-0.35,0.03-0.81,0.13-1.4,0.3l-0.17-0.46l8.57-3.12l0.17,0.46l-0.17,0.06
		c-1.25,0.45-2.05,1.19-2.42,2.23s-0.27,2.3,0.28,3.81l4.5,12.38l-0.41,0.15l-20.52-9.92l3.74,10.28c0.36,1,0.77,1.76,1.23,2.27
		c0.46,0.51,1.02,0.84,1.68,0.98c0.53,0.11,1.13,0.08,1.78-0.07c0.37-0.07,0.88-0.21,1.53-0.42l0.17,0.46l-9.03,3.28l-0.17-0.46
		c0.91-0.39,1.6-0.82,2.08-1.31c0.48-0.49,0.76-1.06,0.84-1.7c0.08-0.65-0.03-1.37-0.32-2.18l-3.56-9.79
		c-0.22-0.6-0.54-1.07-0.96-1.39c-0.42-0.32-0.93-0.51-1.52-0.55s-1.26,0.07-1.98,0.33l-0.22,0.08L95.91,14.46z"/>
                <path d="M132.25,2.92l12.14-1.44c1.97-0.23,3.72-0.19,5.25,0.13c1.53,0.32,2.86,0.93,3.98,1.83c1.01,0.77,1.83,1.69,2.46,2.74
		c0.62,1.05,1.01,2.16,1.15,3.33c0.15,1.28,0.03,2.5-0.38,3.66s-1.04,2.19-1.92,3.1c-0.88,0.91-1.96,1.66-3.24,2.25
		c-1.28,0.59-2.7,0.98-4.26,1.16l-13,1.54l-0.06-0.51l0.67-0.08c1.13-0.13,1.93-0.51,2.41-1.12c0.48-0.61,0.65-1.48,0.52-2.6
		L136.7,6.25c-0.13-1.13-0.5-1.94-1.11-2.42c-0.61-0.48-1.48-0.65-2.61-0.51l-0.67,0.08L132.25,2.92z M140.17,2.47l2.05,17.29
		l3.53-0.42c1-0.12,1.85-0.28,2.53-0.47s1.27-0.45,1.77-0.77c0.49-0.32,0.91-0.71,1.25-1.18c1.27-1.65,1.73-4,1.37-7.07
		c-0.12-1-0.32-1.94-0.61-2.82c-0.29-0.87-0.64-1.63-1.06-2.27c-0.42-0.64-0.92-1.19-1.5-1.64c-0.48-0.37-1-0.65-1.57-0.84
		s-1.21-0.31-1.93-0.34c-0.72-0.03-1.57,0.01-2.55,0.13L140.17,2.47z"/>
                <path d="M161.07,0l19.58,0.77l-0.28,7.13l-0.4-0.02c-0.31-1.26-0.6-2.21-0.86-2.84c-0.26-0.63-0.61-1.23-1.04-1.8
		c-0.53-0.68-1.11-1.17-1.75-1.48c-1.06-0.49-2.37-0.77-3.91-0.83l-3.62-0.14l-0.34,8.75l0.97,0.04c1.45,0.06,2.62-0.52,3.52-1.74
		c0.46-0.62,0.82-1.38,1.07-2.25c0.12-0.37,0.24-0.93,0.36-1.66l0.46,0.02l-0.47,12.07l-0.46-0.02c-0.08-0.65-0.18-1.27-0.3-1.85
		c-0.12-0.58-0.27-1.05-0.43-1.42c-0.17-0.37-0.38-0.74-0.65-1.11c-0.75-1.01-1.86-1.54-3.32-1.59l-0.76-0.03l-0.32,8.23l3.03,0.12
		c0.9,0.04,1.7,0,2.42-0.1c0.72-0.1,1.37-0.27,1.95-0.52c0.58-0.25,1.13-0.57,1.65-0.98c0.89-0.7,1.59-1.58,2.11-2.65
		c0.3-0.61,0.61-1.44,0.96-2.5l0.49,0.02l-0.3,7.58l-20.04-0.79l-0.01-0.49l0.5,0.02c2.23,0.09,3.39-0.99,3.48-3.24l0.42-10.71
		c0.04-1.14-0.19-2-0.72-2.56c-0.52-0.57-1.36-0.87-2.5-0.92l-0.5-0.02L161.07,0z"/>
                <path d="M195.81,12.75l-4.06-0.82l-1.02,5.05c-0.22,1.1-0.12,1.98,0.32,2.63c0.43,0.65,1.2,1.09,2.3,1.31l0.53,0.11l-0.1,0.5
		l-11.4-2.29l0.1-0.5l0.49,0.1c1.11,0.22,1.99,0.12,2.64-0.31s1.08-1.2,1.31-2.31l2.11-10.51c0.23-1.12,0.12-2-0.3-2.64
		c-0.43-0.64-1.2-1.08-2.32-1.3l-0.49-0.1l0.1-0.48l14,2.82c1.9,0.38,3.37,0.82,4.42,1.32c1.05,0.5,1.83,1.14,2.34,1.92
		c0.3,0.44,0.5,0.94,0.62,1.5c0.12,0.55,0.13,1.08,0.03,1.58c-0.27,1.36-1.2,2.31-2.78,2.86c-0.58,0.18-1.35,0.29-2.3,0.33
		c-0.95,0.04-1.88,0.01-2.79-0.09c-0.69-0.09-1.73-0.27-3.13-0.55l-0.01,0.05c0.6,0.59,1.15,1.08,1.65,1.49
		c0.5,0.41,1.02,0.77,1.54,1.08c0.53,0.32,1.14,0.63,1.83,0.94l0.3,0.73c0.2,0.47,0.35,0.82,0.45,1.06c0.1,0.23,0.21,0.5,0.33,0.81
		c0.33,0.83,0.67,1.56,0.99,2.18s0.61,1.08,0.85,1.35c0.49,0.48,1.25,0.83,2.29,1.04l0.5,0.1l-0.1,0.5l-6.77-1.36L195.81,12.75z
		 M191.85,11.45l5.14,1.04c1.21,0.24,2.24,0.3,3.08,0.16c0.84-0.14,1.51-0.48,2.02-1.01c0.5-0.53,0.85-1.27,1.05-2.23
		c0.55-2.74-0.92-4.46-4.41-5.16l-5.22-1.05L191.85,11.45z"/>
                <path d="M215.84,26.59l0.54,0.25c1.03,0.48,1.91,0.58,2.64,0.31s1.33-0.92,1.81-1.95c0.26-0.56,0.5-1.09,0.74-1.6
		s0.61-1.32,1.12-2.42c0.49-1.06,1.04-2.24,1.64-3.55c0.6-1.3,1-2.17,1.2-2.59c0.16-0.54,0.25-0.99,0.26-1.35
		c0.01-0.36-0.07-0.7-0.24-1.03c-0.28-0.64-0.9-1.19-1.86-1.63l-0.57-0.27l0.2-0.44l10.55,4.87l-0.2,0.44l-0.37-0.17
		c-1.03-0.48-1.91-0.58-2.64-0.31c-0.73,0.27-1.33,0.92-1.81,1.95l-2.07,4.49l9,4.15l2.07-4.49c0.48-1.04,0.58-1.92,0.32-2.64
		s-0.92-1.32-1.96-1.8l-0.35-0.16l0.2-0.44l10.45,4.82l-0.2,0.44l-0.49-0.23c-1.02-0.47-1.9-0.57-2.63-0.31
		c-0.73,0.27-1.34,0.92-1.81,1.94l-4.49,9.73c-0.47,1.02-0.57,1.9-0.29,2.64s0.92,1.34,1.93,1.8l0.49,0.23l-0.21,0.47l-10.45-4.82
		l0.22-0.47l0.35,0.16c1.03,0.48,1.91,0.58,2.64,0.31c0.73-0.27,1.33-0.92,1.81-1.95l2.19-4.75l-9-4.15l-2.19,4.75
		c-0.47,1.02-0.57,1.9-0.29,2.64c0.28,0.74,0.92,1.34,1.93,1.8l0.37,0.17l-0.21,0.47l-10.51-4.85L215.84,26.59z"/>
                <path d="M249.69,23.07L265.95,34l-3.98,5.92l-0.33-0.22c0.4-1.24,0.65-2.19,0.76-2.87c0.11-0.67,0.13-1.37,0.06-2.08
		c-0.09-0.86-0.33-1.58-0.71-2.18c-0.65-0.98-1.61-1.9-2.89-2.76l-3.01-2.02l-4.89,7.26l0.81,0.54c1.2,0.81,2.51,0.94,3.91,0.38
		c0.72-0.29,1.42-0.74,2.09-1.36c0.3-0.25,0.69-0.66,1.18-1.23l0.38,0.26l-6.74,10.02l-0.38-0.26c0.28-0.6,0.51-1.18,0.71-1.74
		s0.33-1.04,0.38-1.44c0.05-0.4,0.06-0.83,0.03-1.28c-0.11-1.25-0.77-2.28-1.98-3.1l-0.63-0.43l-4.6,6.84l2.52,1.69
		c0.74,0.5,1.45,0.9,2.11,1.19c0.67,0.29,1.31,0.49,1.93,0.59c0.62,0.1,1.26,0.11,1.92,0.03c1.12-0.12,2.18-0.51,3.19-1.15
		c0.57-0.37,1.28-0.91,2.12-1.62l0.41,0.27l-4.23,6.29l-16.63-11.2l0.25-0.42l0.42,0.28c1.85,1.25,3.41,0.94,4.66-0.93l5.99-8.89
		c0.64-0.95,0.88-1.8,0.74-2.56s-0.7-1.45-1.64-2.09l-0.42-0.28L249.69,23.07z"/>
                <path d="M258.04,50.73l0.39,0.34c0.86,0.74,1.68,1.08,2.45,1.02c0.78-0.06,1.53-0.52,2.27-1.38c0.36-0.31,0.68-0.6,0.95-0.85
		c0.27-0.25,0.56-0.55,0.87-0.9s0.7-0.8,1.16-1.34c1.93-2.3,3.34-4.07,4.23-5.32c0.36-0.5,0.6-0.93,0.71-1.28
		c0.11-0.35,0.14-0.71,0.06-1.08c-0.1-0.7-0.54-1.39-1.34-2.08l-0.39-0.34l0.32-0.37l8.79,7.57l-0.32,0.37l-0.39-0.34
		c-0.86-0.74-1.67-1.08-2.44-1.02c-0.77,0.06-1.53,0.52-2.27,1.39l-6.99,8.12c-0.74,0.86-1.08,1.68-1.02,2.45
		c0.06,0.77,0.51,1.53,1.37,2.26l0.39,0.33l-0.34,0.39l-8.79-7.57L258.04,50.73z"/>
                <path d="M284.53,50.66l8.67,9.27l-0.36,0.33l-0.37-0.4c-1.54-1.64-3.12-1.7-4.75-0.18l-6.53,6.11c-1.25,1.17-2.58,1.72-3.99,1.66
		c-0.94-0.05-1.87-0.32-2.79-0.8c-0.92-0.48-1.77-1.14-2.55-1.97c-0.73-0.78-1.27-1.58-1.63-2.41c-0.36-0.83-0.53-1.64-0.5-2.44
		c0.04-0.51,0.14-1,0.32-1.46c0.18-0.46,0.42-0.83,0.72-1.11c0.49-0.46,1.08-0.69,1.77-0.67c0.68,0.02,1.26,0.27,1.72,0.77
		c0.46,0.49,0.68,1.04,0.65,1.65s-0.28,1.13-0.77,1.59c-0.78,0.73-1.72,0.8-2.83,0.22c-0.34-0.18-0.55-0.22-0.66-0.12
		c-0.17,0.16-0.19,0.52-0.07,1.08c0.14,0.71,0.56,1.43,1.24,2.16c0.56,0.6,1.14,1.01,1.75,1.23s1.22,0.26,1.83,0.11
		s1.2-0.49,1.76-1.02l7.9-7.39c1.63-1.52,1.68-3.11,0.14-4.75L284.17,51L284.53,50.66z"/>
                <path d="M295.71,62.62l6.94,10.06c1.13,1.63,1.91,3.2,2.34,4.7c0.43,1.5,0.51,2.96,0.24,4.38c-0.21,1.26-0.64,2.41-1.27,3.45
		c-0.64,1.04-1.44,1.9-2.41,2.57c-1.06,0.73-2.2,1.19-3.41,1.37c-1.21,0.18-2.42,0.1-3.64-0.26c-1.21-0.35-2.38-0.96-3.5-1.82
		c-1.12-0.86-2.13-1.93-3.01-3.22l-7.43-10.78l0.42-0.29l0.38,0.55c0.64,0.93,1.35,1.47,2.11,1.61c0.76,0.14,1.61-0.11,2.55-0.75
		l8.82-6.08c0.94-0.65,1.48-1.35,1.62-2.11c0.14-0.76-0.12-1.61-0.76-2.55l-0.38-0.55L295.71,62.62z M299.79,69.41l-14.34,9.89
		l2.02,2.93c0.57,0.83,1.11,1.51,1.6,2.02c0.49,0.51,1,0.91,1.51,1.2c0.51,0.29,1.05,0.47,1.63,0.56c2.05,0.36,4.35-0.34,6.89-2.09
		c0.83-0.57,1.57-1.19,2.21-1.85s1.14-1.33,1.51-2s0.62-1.37,0.75-2.09c0.1-0.59,0.11-1.19,0.01-1.78c-0.09-0.59-0.29-1.21-0.6-1.86
		c-0.31-0.65-0.74-1.38-1.31-2.2L299.79,69.41z"/>
                <path d="M311.72,86.72l8.43,17.69l-6.44,3.07l-0.17-0.36c0.97-0.86,1.68-1.56,2.11-2.08s0.81-1.11,1.11-1.76
		c0.36-0.78,0.52-1.53,0.5-2.24c-0.06-1.17-0.42-2.45-1.09-3.85l-1.56-3.27l-7.9,3.77l0.42,0.88c0.62,1.31,1.68,2.08,3.18,2.31
		c0.77,0.12,1.6,0.09,2.49-0.11c0.39-0.07,0.93-0.22,1.64-0.46l0.2,0.42l-10.9,5.19l-0.2-0.42c0.54-0.38,1.04-0.75,1.5-1.13
		c0.46-0.38,0.81-0.73,1.06-1.04c0.25-0.32,0.47-0.68,0.68-1.09c0.54-1.13,0.5-2.36-0.13-3.68l-0.33-0.69l-7.44,3.54l1.3,2.74
		c0.39,0.81,0.79,1.51,1.21,2.1s0.88,1.09,1.37,1.49c0.49,0.4,1.03,0.73,1.64,1c1.03,0.46,2.14,0.68,3.33,0.64
		c0.68-0.02,1.57-0.13,2.65-0.32l0.21,0.44l-6.84,3.26l-8.63-18.1l0.43-0.23l0.21,0.45c0.96,2.02,2.46,2.54,4.49,1.57l9.68-4.61
		c1.03-0.49,1.68-1.1,1.93-1.83c0.26-0.73,0.14-1.61-0.35-2.64l-0.21-0.45L311.72,86.72z"/>
                <path d="M322.17,109.09l2.08,7.1l-9.12,16.02l7.62-2.23c0.93-0.27,1.69-0.67,2.3-1.19c0.6-0.52,1-1.13,1.18-1.84
		c0.11-0.38,0.16-0.74,0.15-1.09s-0.08-0.82-0.21-1.42l0.47-0.14l2.57,8.75l-0.47,0.14l-0.05-0.18c-0.37-1.27-1.06-2.12-2.07-2.55
		s-2.28-0.42-3.82,0.04l-12.64,3.71l-0.12-0.42l11.2-19.86l-10.5,3.08c-1.02,0.3-1.8,0.66-2.34,1.09c-0.54,0.42-0.9,0.96-1.09,1.61
		c-0.14,0.53-0.15,1.12-0.04,1.78c0.04,0.37,0.15,0.89,0.32,1.56l-0.47,0.14l-2.71-9.22l0.47-0.14c0.33,0.93,0.72,1.65,1.18,2.16
		s1,0.83,1.64,0.95s1.37,0.06,2.2-0.19l9.99-2.93c0.62-0.18,1.1-0.47,1.45-0.87c0.35-0.4,0.56-0.9,0.64-1.49
		c0.08-0.59,0.01-1.26-0.21-2l-0.07-0.23L322.17,109.09z"/>
            </g>
            <circle cx="6.21" cy="168.02" r="3.83" />
            <circle cx="323.1" cy="168.02" r="3.83" />
            <g>
                <path d="M325.56,215.56l-3.87,12.09l-0.46-0.15l0.16-0.51c0.69-2.14-0.04-3.55-2.16-4.24l-8.51-2.72
		c-1.63-0.52-2.76-1.42-3.39-2.68c-0.41-0.84-0.63-1.79-0.66-2.83c-0.03-1.04,0.13-2.1,0.48-3.18c0.32-1.01,0.76-1.88,1.31-2.6
		c0.55-0.72,1.18-1.26,1.88-1.62c0.47-0.22,0.94-0.36,1.43-0.43c0.49-0.07,0.93-0.04,1.32,0.09c0.64,0.21,1.13,0.61,1.44,1.22
		c0.32,0.61,0.38,1.23,0.17,1.87c-0.21,0.64-0.58,1.1-1.12,1.37c-0.54,0.27-1.13,0.3-1.76,0.1c-1.01-0.32-1.54-1.11-1.57-2.36
		c-0.01-0.38-0.08-0.59-0.21-0.64c-0.22-0.07-0.54,0.08-0.98,0.46c-0.55,0.47-0.97,1.18-1.28,2.14c-0.25,0.78-0.32,1.49-0.22,2.13
		c0.1,0.64,0.36,1.19,0.79,1.65c0.43,0.46,1.01,0.81,1.75,1.04l10.31,3.3c2.13,0.68,3.53-0.05,4.22-2.19l0.47-1.47L325.56,215.56z"
                />
                <path d="M320.5,230.9l-8.43,17.68l-6.44-3.07l0.17-0.36c1.28,0.21,2.26,0.32,2.95,0.33c0.68,0.01,1.37-0.07,2.07-0.25
		c0.83-0.22,1.52-0.56,2.05-1.02c0.87-0.78,1.64-1.87,2.3-3.27l1.56-3.27l-7.9-3.77l-0.42,0.88c-0.62,1.31-0.56,2.62,0.2,3.92
		c0.39,0.67,0.94,1.3,1.65,1.87c0.3,0.26,0.76,0.58,1.39,0.98l-0.2,0.42l-10.9-5.2l0.2-0.42c0.63,0.18,1.24,0.34,1.82,0.45
		s1.07,0.17,1.48,0.16c0.4-0.01,0.83-0.06,1.27-0.16c1.22-0.29,2.15-1.1,2.77-2.42l0.33-0.69l-7.44-3.55l-1.3,2.74
		c-0.39,0.81-0.67,1.56-0.87,2.27s-0.29,1.37-0.3,2c0,0.63,0.08,1.26,0.25,1.9c0.29,1.09,0.82,2.08,1.6,2.98
		c0.45,0.51,1.08,1.13,1.92,1.86l-0.21,0.44l-6.84-3.26l8.63-18.1l0.45,0.19l-0.22,0.45c-0.96,2.02-0.43,3.51,1.61,4.48l9.68,4.61
		c1.03,0.49,1.91,0.61,2.64,0.35s1.33-0.9,1.83-1.93l0.22-0.45L320.5,230.9z"/>
                <path d="M305.05,258.92l-6.64-4.44l-0.64,0.96c-0.68,1.02-0.88,2.03-0.59,3.04c0.28,1.01,1.08,2.14,2.4,3.38l-0.26,0.38l-8.78-5.87
		l0.27-0.4c0.84,0.39,1.54,0.65,2.09,0.78s1.11,0.17,1.66,0.11c1.18-0.11,2.16-0.74,2.94-1.9l0.51-0.76l-4.65-3.11
		c-0.94-0.63-1.79-0.87-2.56-0.72c-0.76,0.15-1.46,0.69-2.08,1.63l-0.65,0.97l-0.43-0.29l6.79-10.15l0.43,0.29l-0.26,0.38
		c-0.63,0.94-0.87,1.79-0.72,2.56c0.15,0.76,0.7,1.46,1.64,2.09l8.91,5.96c0.95,0.63,1.8,0.88,2.56,0.73
		c0.76-0.15,1.45-0.7,2.09-1.65l0.26-0.38l0.41,0.27l-11.2,16.75l-6.62-4.43l0.24-0.36c0.64,0.27,1.25,0.5,1.81,0.69
		c0.56,0.19,1.01,0.3,1.35,0.35c1.06,0.18,2.02,0.18,2.89,0.01c0.87-0.17,1.67-0.53,2.41-1.07s1.4-1.27,2.01-2.17L305.05,258.92z"/>
                <path d="M290.51,278.16l-5.94-5.35l-0.78,0.86c-0.82,0.91-1.16,1.88-1.02,2.93c0.13,1.04,0.77,2.28,1.89,3.69l-0.31,0.34
		l-7.85-7.07l0.33-0.36c0.77,0.51,1.43,0.87,1.96,1.07c0.53,0.21,1.07,0.32,1.62,0.35c1.19,0.06,2.25-0.43,3.18-1.46l0.61-0.68
		l-4.15-3.74c-0.84-0.76-1.65-1.12-2.43-1.08c-0.78,0.04-1.54,0.48-2.29,1.31l-0.79,0.87l-0.38-0.34l8.17-9.07l0.38,0.34l-0.31,0.34
		c-0.76,0.84-1.12,1.65-1.08,2.43c0.04,0.78,0.48,1.54,1.32,2.3l7.96,7.17c0.85,0.76,1.66,1.13,2.43,1.09s1.54-0.49,2.3-1.33
		l0.31-0.34l0.36,0.33l-13.48,14.97l-5.92-5.33l0.29-0.32c0.6,0.36,1.16,0.67,1.69,0.94c0.53,0.27,0.96,0.45,1.29,0.54
		c1.02,0.33,1.97,0.47,2.86,0.42c0.89-0.05,1.73-0.29,2.54-0.72c0.8-0.43,1.57-1.05,2.3-1.86L290.51,278.16z"/>
                <path d="M272.77,296.73l-8.69,6.37l-0.29-0.39l1.06-0.78c0.48-0.35,0.79-0.73,0.92-1.13c0.13-0.4,0.06-0.78-0.2-1.14
		c-0.25-0.34-0.65-0.67-1.2-0.99l-10.57-5.92l2.22,10.16c0.16,0.79,0.4,1.39,0.71,1.82c0.45,0.61,0.99,0.96,1.64,1.04
		s1.32-0.1,2.02-0.56l0.29,0.39l-5.99,4.39l-0.29-0.39c0.52-0.48,0.91-1.02,1.16-1.61c0.25-0.59,0.38-1.27,0.38-2.04
		c0-0.77-0.11-1.71-0.35-2.81l-3.22-14.73l0.36-0.29l14.95,8.32c1.77,0.98,3.19,1.08,4.27,0.29l0.52-0.38L272.77,296.73z"/>
                <path d="M247.12,314.53l-0.42,0.24l-14.35-10.42c-0.45-0.32-0.89-0.55-1.3-0.69c-0.42-0.14-0.82-0.18-1.21-0.13
		c-0.39,0.05-0.81,0.21-1.24,0.45l-0.42,0.24l-0.25-0.45l9.32-5.24l0.25,0.45l-1.22,0.68c-0.61,0.34-1,0.67-1.19,0.99
		c-0.19,0.32-0.18,0.65,0.01,1c0.12,0.21,0.32,0.44,0.61,0.69s0.84,0.66,1.67,1.26c0.05,0.03,0.13,0.09,0.23,0.17
		c0.11,0.08,0.21,0.16,0.32,0.23l0.59,0.44l6.8-3.82l-0.16-1.49c-0.06-0.5-0.21-0.96-0.44-1.37c-0.2-0.35-0.49-0.65-0.88-0.88
		s-0.79-0.35-1.2-0.35c-0.28-0.01-0.51,0.02-0.7,0.07c-0.19,0.05-0.44,0.15-0.75,0.31l-0.25-0.45l6.54-3.67l0.25,0.45
		c-0.45,0.32-0.78,0.62-0.99,0.89s-0.42,0.64-0.61,1.1c-0.46,1.08-0.59,2.71-0.38,4.89L247.12,314.53z M246.22,310.08l-0.88-8.93
		l-6.38,3.59L246.22,310.08z"/>
                <path d="M233.3,321.58l-6.95,2.53l-16.56-8.09l2.71,7.46c0.33,0.91,0.77,1.65,1.33,2.21c0.56,0.57,1.19,0.92,1.91,1.07
		c0.38,0.08,0.75,0.11,1.1,0.08c0.35-0.03,0.82-0.13,1.4-0.3l0.17,0.46l-8.57,3.12l-0.17-0.46l0.17-0.06
		c1.25-0.45,2.05-1.19,2.42-2.22s0.27-2.3-0.28-3.81l-4.5-12.38l0.41-0.15l20.52,9.92l-3.74-10.28c-0.36-1-0.77-1.76-1.23-2.27
		c-0.46-0.51-1.02-0.84-1.68-0.98c-0.53-0.11-1.13-0.08-1.78,0.07c-0.37,0.07-0.88,0.21-1.53,0.42l-0.17-0.46l9.03-3.28l0.17,0.46
		c-0.91,0.39-1.6,0.82-2.08,1.31c-0.48,0.49-0.76,1.05-0.84,1.7c-0.08,0.65,0.03,1.37,0.32,2.18l3.56,9.79
		c0.22,0.6,0.54,1.07,0.96,1.39c0.42,0.32,0.93,0.51,1.52,0.55s1.26-0.07,1.98-0.34l0.22-0.08L233.3,321.58z"/>
                <path d="M196.95,333.12l-12.14,1.44c-1.97,0.23-3.72,0.19-5.25-0.13s-2.86-0.93-3.98-1.83c-1.01-0.77-1.83-1.69-2.46-2.74
		c-0.62-1.05-1.01-2.16-1.15-3.33c-0.15-1.28-0.03-2.5,0.38-3.66c0.4-1.15,1.04-2.19,1.92-3.1c0.88-0.91,1.96-1.66,3.24-2.25
		c1.28-0.59,2.7-0.98,4.26-1.16l13-1.54l0.06,0.51l-0.67,0.08c-1.13,0.13-1.93,0.51-2.41,1.12s-0.65,1.48-0.52,2.6l1.26,10.64
		c0.13,1.13,0.5,1.94,1.11,2.42s1.48,0.65,2.61,0.51l0.67-0.08L196.95,333.12z M189.04,333.57l-2.05-17.29l-3.54,0.42
		c-1,0.12-1.85,0.28-2.53,0.47c-0.68,0.2-1.27,0.46-1.77,0.77s-0.91,0.71-1.25,1.18c-1.27,1.65-1.73,4-1.37,7.07
		c0.12,1,0.32,1.94,0.61,2.82c0.29,0.87,0.64,1.63,1.06,2.27s0.92,1.19,1.5,1.64c0.48,0.37,1,0.65,1.57,0.84
		c0.57,0.19,1.21,0.31,1.92,0.34c0.72,0.03,1.57-0.01,2.55-0.13L189.04,333.57z"/>
                <path d="M168.14,336.04l-19.58-0.77l0.28-7.13l0.4,0.02c0.31,1.26,0.6,2.21,0.86,2.84c0.26,0.63,0.61,1.23,1.04,1.8
		c0.53,0.68,1.11,1.17,1.75,1.48c1.06,0.49,2.37,0.77,3.91,0.83l3.62,0.14l0.34-8.75l-0.97-0.04c-1.45-0.06-2.62,0.52-3.52,1.73
		c-0.46,0.62-0.82,1.38-1.07,2.25c-0.12,0.37-0.24,0.93-0.36,1.66l-0.46-0.02l0.47-12.07l0.46,0.02c0.08,0.65,0.18,1.27,0.3,1.85
		c0.12,0.58,0.27,1.05,0.43,1.42c0.17,0.37,0.38,0.74,0.65,1.11c0.75,1.01,1.86,1.54,3.32,1.59l0.76,0.03l0.32-8.23l-3.03-0.12
		c-0.9-0.04-1.7,0-2.42,0.1s-1.37,0.27-1.95,0.52c-0.58,0.24-1.13,0.57-1.65,0.98c-0.89,0.7-1.59,1.58-2.11,2.65
		c-0.3,0.61-0.61,1.44-0.96,2.5l-0.49-0.02l0.3-7.58l20.04,0.79l0.01,0.49l-0.5-0.02c-2.23-0.09-3.39,0.99-3.48,3.24l-0.42,10.71
		c-0.04,1.14,0.19,2,0.72,2.56s1.36,0.87,2.5,0.92l0.5,0.02L168.14,336.04z"/>
                <path d="M133.39,323.29l4.06,0.82l1.02-5.05c0.22-1.1,0.12-1.98-0.32-2.63c-0.43-0.65-1.2-1.09-2.3-1.31l-0.53-0.11l0.1-0.5
		l11.4,2.29l-0.1,0.5l-0.49-0.1c-1.11-0.22-1.99-0.12-2.64,0.31c-0.65,0.43-1.08,1.2-1.31,2.31l-2.11,10.51
		c-0.23,1.12-0.12,2,0.3,2.64c0.43,0.64,1.2,1.08,2.32,1.3l0.49,0.1l-0.1,0.48l-14-2.82c-1.9-0.38-3.37-0.82-4.42-1.32
		c-1.05-0.5-1.83-1.14-2.34-1.92c-0.3-0.44-0.5-0.94-0.62-1.5c-0.12-0.55-0.13-1.08-0.03-1.58c0.27-1.36,1.2-2.31,2.78-2.86
		c0.58-0.18,1.35-0.29,2.3-0.33c0.95-0.04,1.88-0.01,2.79,0.09c0.69,0.08,1.73,0.27,3.13,0.55l0.01-0.05
		c-0.6-0.59-1.15-1.08-1.65-1.49c-0.5-0.41-1.02-0.77-1.54-1.08c-0.53-0.32-1.14-0.63-1.83-0.94l-0.3-0.73
		c-0.2-0.47-0.35-0.82-0.45-1.06c-0.1-0.23-0.21-0.5-0.33-0.81c-0.33-0.83-0.67-1.56-0.99-2.18s-0.61-1.08-0.85-1.35
		c-0.49-0.48-1.25-0.83-2.29-1.04l-0.5-0.1l0.1-0.5l6.77,1.36L133.39,323.29z M137.36,324.59l-5.14-1.04
		c-1.21-0.24-2.24-0.3-3.08-0.16c-0.84,0.14-1.51,0.47-2.02,1.01c-0.5,0.53-0.85,1.27-1.05,2.23c-0.55,2.74,0.92,4.46,4.41,5.16
		l5.22,1.05L137.36,324.59z"/>
                <path d="M113.37,309.45l-0.54-0.25c-1.03-0.47-1.91-0.58-2.64-0.31s-1.33,0.92-1.81,1.95c-0.26,0.56-0.5,1.09-0.74,1.6
		c-0.24,0.51-0.61,1.32-1.12,2.42c-0.49,1.06-1.04,2.24-1.64,3.55c-0.6,1.3-1,2.17-1.2,2.59c-0.16,0.54-0.25,1-0.26,1.35
		c-0.01,0.36,0.07,0.7,0.24,1.03c0.28,0.64,0.9,1.19,1.86,1.63l0.57,0.26l-0.2,0.44l-10.55-4.87l0.2-0.44l0.37,0.17
		c1.03,0.47,1.91,0.58,2.64,0.31c0.73-0.27,1.33-0.92,1.81-1.95l2.07-4.49l-9-4.15l-2.07,4.49c-0.48,1.04-0.58,1.92-0.32,2.64
		c0.27,0.72,0.92,1.32,1.96,1.8l0.35,0.16l-0.2,0.44l-10.45-4.82l0.2-0.44l0.49,0.23c1.02,0.47,1.9,0.57,2.63,0.31
		c0.73-0.27,1.34-0.91,1.81-1.94l4.49-9.73c0.47-1.02,0.57-1.9,0.29-2.64s-0.92-1.34-1.93-1.8l-0.49-0.23l0.21-0.47l10.45,4.82
		l-0.22,0.47l-0.35-0.16c-1.03-0.47-1.91-0.58-2.64-0.31c-0.73,0.27-1.33,0.92-1.81,1.95l-2.19,4.75l9,4.15l2.19-4.75
		c0.47-1.02,0.57-1.9,0.29-2.64c-0.28-0.74-0.92-1.34-1.93-1.8l-0.37-0.17l0.21-0.47l10.51,4.85L113.37,309.45z"/>
                <path d="M79.51,312.98l-16.25-10.94l3.98-5.92l0.33,0.22c-0.4,1.24-0.65,2.19-0.76,2.87c-0.11,0.67-0.13,1.37-0.06,2.08
		c0.09,0.86,0.33,1.58,0.71,2.18c0.65,0.98,1.61,1.9,2.89,2.76l3.01,2.02l4.89-7.26l-0.81-0.54c-1.2-0.81-2.51-0.93-3.91-0.38
		c-0.72,0.29-1.42,0.74-2.09,1.36c-0.3,0.25-0.69,0.66-1.18,1.23l-0.38-0.26l6.74-10.02l0.38,0.26c-0.28,0.6-0.51,1.18-0.71,1.74
		s-0.33,1.04-0.38,1.44c-0.05,0.4-0.06,0.83-0.03,1.28c0.11,1.25,0.77,2.28,1.98,3.1l0.63,0.43l4.6-6.84l-2.52-1.69
		c-0.74-0.5-1.45-0.9-2.11-1.19c-0.67-0.29-1.31-0.49-1.93-0.59c-0.62-0.1-1.26-0.11-1.92-0.03c-1.12,0.12-2.18,0.51-3.19,1.14
		c-0.57,0.37-1.28,0.91-2.12,1.62l-0.41-0.27l4.23-6.29l16.63,11.2l-0.25,0.42l-0.42-0.28c-1.85-1.25-3.41-0.94-4.66,0.93
		l-5.99,8.89c-0.64,0.95-0.88,1.8-0.74,2.56c0.15,0.76,0.7,1.45,1.64,2.09l0.42,0.28L79.51,312.98z"/>
                <path d="M71.16,285.31l-0.39-0.34c-0.86-0.74-1.68-1.08-2.45-1.02c-0.78,0.06-1.53,0.52-2.27,1.38c-0.36,0.31-0.68,0.6-0.95,0.85
		c-0.27,0.25-0.56,0.55-0.87,0.9c-0.31,0.35-0.7,0.8-1.16,1.34c-1.93,2.3-3.34,4.07-4.23,5.32c-0.36,0.5-0.6,0.93-0.71,1.28
		c-0.11,0.36-0.14,0.71-0.06,1.08c0.1,0.7,0.54,1.39,1.34,2.08l0.39,0.33l-0.32,0.37l-8.79-7.57l0.32-0.37l0.39,0.34
		c0.86,0.74,1.67,1.08,2.44,1.02c0.77-0.06,1.53-0.52,2.27-1.38l6.99-8.12c0.74-0.86,1.08-1.68,1.02-2.45
		c-0.06-0.77-0.51-1.53-1.37-2.26l-0.39-0.34l0.34-0.39l8.79,7.57L71.16,285.31z"/>
                <path d="M44.68,285.38L36,276.11l0.36-0.33l0.37,0.39c1.54,1.64,3.12,1.7,4.75,0.18l6.53-6.11c1.25-1.17,2.58-1.72,3.99-1.66
		c0.94,0.05,1.87,0.32,2.79,0.8c0.92,0.48,1.77,1.14,2.55,1.97c0.73,0.78,1.27,1.58,1.63,2.41c0.36,0.83,0.53,1.64,0.5,2.44
		c-0.04,0.52-0.14,1-0.32,1.46c-0.18,0.46-0.42,0.83-0.72,1.11c-0.49,0.46-1.08,0.68-1.77,0.67c-0.68-0.02-1.26-0.27-1.72-0.77
		c-0.46-0.49-0.68-1.04-0.65-1.65s0.28-1.13,0.77-1.59c0.78-0.73,1.72-0.8,2.83-0.22c0.34,0.18,0.55,0.22,0.66,0.12
		c0.17-0.16,0.19-0.52,0.07-1.08c-0.14-0.71-0.56-1.43-1.24-2.16c-0.56-0.6-1.14-1.01-1.75-1.23s-1.22-0.26-1.83-0.11
		s-1.2,0.49-1.76,1.02l-7.9,7.39c-1.63,1.53-1.68,3.11-0.14,4.75l1.05,1.13L44.68,285.38z"/>
                <path d="M33.5,273.42l-6.94-10.06c-1.13-1.63-1.91-3.2-2.34-4.7c-0.43-1.5-0.51-2.96-0.24-4.38c0.21-1.26,0.64-2.41,1.27-3.45
		c0.64-1.04,1.44-1.9,2.41-2.57c1.06-0.73,2.2-1.19,3.41-1.37c1.21-0.18,2.42-0.1,3.64,0.25c1.21,0.35,2.38,0.96,3.5,1.82
		c1.12,0.86,2.13,1.93,3.01,3.22l7.43,10.78l-0.42,0.29l-0.38-0.55c-0.64-0.93-1.35-1.47-2.11-1.61c-0.76-0.14-1.61,0.11-2.55,0.75
		l-8.82,6.08c-0.94,0.65-1.48,1.35-1.62,2.11c-0.14,0.76,0.12,1.61,0.76,2.55l0.38,0.55L33.5,273.42z M29.41,266.63l14.34-9.89
		l-2.02-2.93c-0.57-0.83-1.11-1.5-1.6-2.02c-0.49-0.51-1-0.92-1.51-1.2c-0.51-0.29-1.05-0.47-1.63-0.56
		c-2.05-0.36-4.35,0.34-6.89,2.09c-0.83,0.57-1.57,1.19-2.21,1.85s-1.14,1.33-1.51,2c-0.37,0.67-0.62,1.37-0.75,2.09
		c-0.1,0.59-0.11,1.19-0.01,1.78c0.09,0.59,0.29,1.21,0.6,1.86c0.31,0.65,0.74,1.38,1.31,2.2L29.41,266.63z"/>
                <path d="M17.48,249.32l-8.43-17.69l6.44-3.07l0.17,0.36c-0.97,0.86-1.68,1.55-2.11,2.08c-0.44,0.52-0.81,1.11-1.11,1.76
		c-0.36,0.78-0.52,1.53-0.5,2.24c0.06,1.17,0.42,2.45,1.09,3.85l1.56,3.27l7.9-3.76l-0.42-0.88c-0.62-1.31-1.68-2.08-3.18-2.31
		c-0.77-0.12-1.6-0.08-2.49,0.11c-0.39,0.07-0.93,0.22-1.64,0.46l-0.2-0.42l10.9-5.19l0.2,0.42c-0.54,0.38-1.04,0.75-1.5,1.13
		c-0.46,0.38-0.81,0.73-1.06,1.04c-0.25,0.32-0.47,0.68-0.68,1.09c-0.54,1.13-0.5,2.36,0.13,3.68l0.33,0.69l7.44-3.54l-1.3-2.74
		c-0.39-0.81-0.79-1.51-1.21-2.1s-0.88-1.09-1.37-1.49c-0.49-0.4-1.03-0.73-1.64-1c-1.03-0.46-2.14-0.68-3.33-0.63
		c-0.68,0.02-1.57,0.13-2.65,0.31l-0.21-0.44l6.84-3.26l8.63,18.1l-0.43,0.23l-0.21-0.45c-0.96-2.02-2.46-2.54-4.49-1.57l-9.68,4.61
		c-1.03,0.49-1.68,1.1-1.93,1.83c-0.26,0.73-0.14,1.61,0.35,2.64l0.21,0.45L17.48,249.32z"/>
                <path d="M7.03,226.95l-2.08-7.1l9.12-16.02l-7.62,2.23c-0.93,0.27-1.69,0.67-2.3,1.19c-0.6,0.52-1,1.13-1.18,1.84
		c-0.11,0.38-0.16,0.75-0.15,1.09s0.08,0.82,0.21,1.42l-0.47,0.14L0,202.99l0.47-0.14l0.05,0.18c0.37,1.27,1.06,2.12,2.07,2.55
		c1,0.43,2.28,0.42,3.82-0.04l12.64-3.71l0.12,0.42l-11.2,19.86l10.5-3.08c1.02-0.3,1.8-0.66,2.34-1.09
		c0.54-0.42,0.9-0.96,1.09-1.61c0.14-0.53,0.15-1.12,0.04-1.79c-0.04-0.37-0.15-0.89-0.32-1.55l0.47-0.14l2.71,9.22l-0.47,0.14
		c-0.33-0.93-0.72-1.65-1.18-2.16s-1-0.83-1.64-0.95s-1.37-0.06-2.2,0.19l-9.99,2.93c-0.62,0.18-1.1,0.47-1.45,0.87
		s-0.56,0.9-0.64,1.49c-0.08,0.59-0.01,1.26,0.21,2l0.07,0.23L7.03,226.95z"/>
            </g>
        </svg>
    )
}

export default LogoOuter